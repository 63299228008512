<template>
  <div class="whiteList">
    <div class="mainBox">
      <el-card shadow="hover" class="mb10">
        <el-form v-model="filterForm" size="small" label-width="120px">
          <el-row :gutter="10">
            <el-col :xs="24" :sm="24" :md="6" :lg="6" :xl="6">
              <el-form-item label="Strategy Code" class="mb0">
                <el-input
                  clearable
                  v-model.number="filterForm.strategyCode"
                  placeholder="Please enter Strategy Code"
                ></el-input>
              </el-form-item>
            </el-col>

            <el-col :xs="24" :sm="24" :md="7" :lg="7" :xl="7">
              <el-form-item label-width="0" class="mb0">
                <el-button type="primary" :loading="loading.list" @click="getList(1)"
                  >Search</el-button
                >
                <el-button type="primary" @click="openDialog('add')">Create</el-button>
                <el-button type="primary" @click="openUpdateDialog('update')" plain
                  >Update</el-button
                >
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
      </el-card>

      <el-card shadow="hover">
        <el-table
          v-loading="loading.list"
          ref="tableRef"
          style="width:100%;min-height: 70vmin;"
          stripe
          border
          :data="list"
          highlight-current-row
          aria-setsize="mini"
          size="mini"
          class="mb10"
        >
          <el-table-column label="id" prop="id" align="center"></el-table-column>
          <el-table-column
            label="strategy code"
            prop="dataStrategyCode"
            align="center"
          ></el-table-column>
          <el-table-column label="Status" prop="status" align="center"></el-table-column>
          <el-table-column label="Percentage" prop="percentage" align="center"></el-table-column>
          <el-table-column label="Remark" prop="remark" align="center"></el-table-column>
          <el-table-column label="Create Time" prop="createTime" align="center"></el-table-column>
          <el-table-column label="Update Time" prop="updateTime" align="center"></el-table-column>
          <el-table-column label="Action" align="center">
            <template slot-scope="scope">
              <el-button type="danger" size="mini" @click="deletes(scope.row.id)">Delete</el-button>
              <el-button type="primary" size="mini" @click="openDialog('edit', scope.row)"
                >Edit</el-button
              >
            </template>
          </el-table-column>
        </el-table>
        <el-dialog
          :title="dialog.title"
          :visible.sync="dialog.visible"
          width="30%"
          :close-on-click-modal="false"
          :close-on-press-escape="false"
          :show-close="false"
        >
          <el-form
            v-model="postData"
            size="small"
            :model="postData"
            ref="postDataForm"
            label-width="90px"
            :rules="rules"
          >
            <el-form-item label="StrategyCode" prop="dataStrategyCode" label-width="120px">
              <el-input
                v-model="postData.dataStrategyCode"
                placeholder="Please enter pb strategyCode"
              ></el-input>
            </el-form-item>
            <el-form-item label="Status">
              <el-switch
                v-model="postData.status"
                active-color="#13ce66"
                inactive-color="#ff4949"
                active-text=""
                inactive-text=""
                active-value="on"
                inactive-value="off"
              >
              </el-switch>
            </el-form-item>
            <el-form-item label="Percentage" prop="percentage">
              <el-input
                v-model="postData.percentage"
                placeholder="Please enter pb percentage"
              ></el-input>
            </el-form-item>
            <el-form-item label="Remark" prop="remark">
              <el-input
                v-model.number="postData.remark"
                placeholder="Please enter remark"
              ></el-input>
            </el-form-item>
          </el-form>

          <span slot="footer" class="dialog-footer">
            <el-button @click="closeDialog('postDataForm')" size="mini">取 消</el-button>
            <el-button
              type="primary"
              size="mini"
              @click="submitDialog('postDataForm')"
              :loading="loading.submitBtn"
              >确 定</el-button
            >
          </span>
        </el-dialog>

        <el-dialog
          :title="updateDialog.title"
          :visible.sync="updateDialog.visible"
          width="30%"
          :close-on-click-modal="false"
          :close-on-press-escape="false"
          :show-close="false"
        >
          <el-form
            v-model="postData"
            size="small"
            :model="postData"
            ref="postUpdateDataForm"
            label-width="90px"
            :rules="rules"
          >
            <div v-for="(item, index) in editedDataList" :key="index">
              <el-form-item :label="item.dataStrategyCode">
                <el-input
                  v-model="editedDataList[index].percentage"
                  placeholder="Please enter pb percentage"
                ></el-input>
                <input type="hidden" v-model="editedDataList[index].dataStrategyCode" />
                <input type="hidden" v-model="editedDataList[index].id" />
              </el-form-item>
            </div>
          </el-form>

          <span slot="footer" class="dialog-footer">
            <el-button @click="closeUpdateDialog('postUpdateDataForm')" size="mini"
              >取 消</el-button
            >
            <el-button
              type="primary"
              size="mini"
              @click="submitUpdateDialog('postUpdateDataForm')"
              :loading="loading.submitBtn"
              >确 定</el-button
            >
          </span>
        </el-dialog>
        <pagination
          :pageTotal="total"
          @handleSizeChange="handleSizeChange"
          @handleCurrentChange="handleCurrentChange"
          :pageSize="filterForm.pageSize"
          :currentPage="filterForm.page"
        ></pagination>
      </el-card>
    </div>
  </div>
</template>

<script>
  import algStrategyCon from '../controllers/setting/algStrategy';
  export default {
    name: 'algStrategyList',
    ...algStrategyCon,
  };
</script>

<style></style>
